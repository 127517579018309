<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active">{{'shared.area_do_candidato' | translate}}</li>
      </ol>
    </nav>
    <div class="input-group btn-search" style="margin-bottom: 20px" *ngIf="inscricoes.length > 2">
      <input
        (keydown.enter)="search()"
        [(ngModel)]="input"
        type="search"
        class="form-control"
        placeholder="{{'candidato_inscricoes.pesquisar' | translate}}"
        name="filtro" />
      <button (click)="search()" class="btn btn-secondary" type="button">
        <i class="bi bi-search" aria-hidden="true">&nbsp;</i>
      </button>
    </div>
    <div class="row" *ngFor="let inscricao of inscricoes">
      <div class="form-group col-xs-12">
        <div class="bg-light border rounded-3 mb-3" id="jInscricao_1" style="margin-bottom: 20px">
          <div class="p-3">
            <b>{{ inscricao.campus }}</b
            ><br />
            <span class="text-primary"
              ><b>{{ inscricao.tituloConcurso }}</b></span
            >
            <br />{{'candidato_inscricoes.periodo_de_inscricao' | translate}}:
            <b
              >{{ inscricao.inicioConcurso | date : 'dd/MM/yyyy' }} {{inscricao.horaInicioConcurso.substring(0, 5)}} h {{'shared.ate' | translate}}
              {{ inscricao.fimConcurso | date : 'dd/MM/yyyy' }} {{'shared.as' | translate}} {{ inscricao.horaFimConcurso.substring(0, 5) }} h</b
            >
            <span *ngIf="inscricao.inicioFase2 && inscricao.fimFase2">
              <br />{{'candidato_inscricoes.periodo_da_segunda_fase' | translate}}:
              <b
                >{{ inscricao.inicioFase2 | date : 'dd/MM/yyyy' }} {{'shared.ate' | translate}} {{ inscricao.fimFase2 | date : 'dd/MM/yyyy' }} às
                23:59 h</b
              >
            </span>
            <span *ngIf="inscricao.valor !== 0.0">
              <br />{{'candidato_inscricoes.pagamento' | translate}}: <b>{{ inscricao.pago === true ? 'Confirmado' : 'Pendente' }}</b> 
            </span>
            <br />{{'candidato_inscricoes.protocolo_da_inscricao' | translate}}: <b>{{ inscricao.protocolo }}</b> 
            <br />{{'candidato_inscricoes.inscricao_realizada_em' | translate}}:
            <b>{{ inscricao.data | date : 'dd/MM/yyyy HH:mm:ss' }}</b>
            <span *ngIf="inscricao.status !== 'Não confirmada'"
              ><br />{{'candidato_inscricoes.situacao_da_inscricao' | translate}}: <b>{{ inscricao.status }}</b></span
            >
            <p *ngIf="inscricao.emailContato !== ''" style="font-size: 1rem">
              <span class="text-primary"><b>{{'candidato_inscricoes.email_para_contato' | translate}}:</b> {{ inscricao.emailContato }}</span>
            </p>
            <p *ngIf="inscricao.telefoneContato !== ''" style="font-size: 1rem">
              <span class="text-primary"><b>{{'candidato_inscricoes.telefone_para_contato' | translate}}:</b> {{ inscricao.telefoneContato }}</span>
            </p>

            <div class="row mb-3">
              <div class="col-12 d-grid gap-2 d-md-block pe-0">
                <a routerLink="/candidato/inscricao/{{ inscricao.id }}" class="btn btn-secondary btn-sm me-3">
                  <i class="bi bi-pencil-square" aria-hidden="true">&nbsp;</i> {{'candidato_inscricoes.detalhes_da_inscricao' | translate}}
                </a>
                <a
                  routerLink="/candidato/inscricao/{{ inscricao.id }}/complemento-fase-1"
                  class="btn btn-warning btn-sm me-3">
                  <i class="bi bi-file-earmark-arrow-up" aria-hidden="true">&nbsp;</i> {{'candidato_inscricoes.documentacao_complementar_da_inscricao' | translate}}
                </a>
                <a
                  routerLink="/candidato/inscricao/{{ inscricao.id }}/complemento-fase-2"
                  class="btn btn-warning btn-sm me-3"
                  *ngIf="showSegundaFase(inscricao)">
                  <i class="bi bi-file-earmark-arrow-up" aria-hidden="true">&nbsp;</i> {{'candidato_inscricao_shared.documentacao_complementar_segunda_fase' | translate}}
                </a>
                <a
                  class="btn btn-secondary btn-sm me-3"
                  data-bs-toggle="collapse"
                  href="#andamentos{{ inscricao.id }}"
                  role="button"
                  aria-expanded="false"
                  attr.aria-controls="andamentos{{ inscricao.id }}"
                  *ngIf="inscricao.andamentos.length > 0">
                  <i class="bi bi-list" aria-hidden="true">&nbsp;</i> {{'candidato_inscricoes.andamentos' | translate}}
                </a>
                <a
                  class="btn btn-secondary btn-sm me-3"
                  data-bs-toggle="collapse"
                  href="#documentos{{ inscricao.id }}"
                  role="button"
                  aria-expanded="false"
                  attr.aria-controls="documentos{{ inscricao.id }}"
                  *ngIf="inscricao.documentos.length > 0">
                  <i class="bi bi-file-earmark-text" aria-hidden="true">&nbsp;</i> {{'candidato_inscricoes.documento_enviado_para_o_candidato' | translate}}
                </a>
                <a
                  routerLink="/candidato/inscricao/{{ inscricao.id }}/recurso"
                  class="btn btn-danger btn-sm"
                  *ngIf="inscricao.periodoRecurso">
                  <i class="bi bi-flag" style="margin-right: 5px">&nbsp;</i>{{'candidato_inscricoes.novo_recurso' | translate}}
                </a>
                <a
                  class="btn btn-secondary btn-sm me-3"
                  data-bs-toggle="collapse"
                  href="#inscricao-notas{{ inscricao.id }}"
                  role="button"
                  aria-expanded="false"
                  attr.aria-controls="inscricao-notas{{ inscricao.id }}"
                  *ngIf="inscricao.notasProvas.length > 0">
                  <i class="bi bi-list" aria-hidden="true">&nbsp;</i> {{'candidato_inscricoes.notas' | translate}}
                </a>
              </div>
            </div>

            <!-- NOTAS -->
            <app-candidato-inscricao-notas
              [notas]="inscricao.notasProvas"
              [id]="inscricao.id"
              [collapsed]="false"></app-candidato-inscricao-notas>

            <!-- ANDAMENTOS -->
            <app-andamento
              [andamentos]="inscricao.andamentos"
              [id]="inscricao.id"
              [idConcurso]="inscricao.idConcurso"
              [collapsed]="false"></app-andamento>

            <!-- DOCUMENTOS -->
            <app-documentos
              [documentos]="inscricao.documentos"
              [id]="inscricao.id"
              [idConcurso]="inscricao.idConcurso"
              [collapsed]="false"></app-documentos>

            <!-- ANEXOS -->
            <app-formulario-situacao [idInscricao]="inscricao.id"></app-formulario-situacao>
            <span *ngIf="!isTipoDocente(inscricao.tipo)">
              <!-- PAGAMENTO -->
              <app-pagamento-deposito [inscricao]="inscricao"></app-pagamento-deposito>
              <app-pagamento-boleto [inscricao]="inscricao"></app-pagamento-boleto>
              <app-pagamento-pix [inscricao]="inscricao"></app-pagamento-pix>
              <app-pagamento-comprovante [inscricao]="inscricao"></app-pagamento-comprovante>
              <!-- DEVOLUCAO -->
              <app-pagamento-devolucao [inscricao]="inscricao"></app-pagamento-devolucao>  
            </span>
          </div>
        </div>
      </div>
    </div>
    <app-pagination
      [current]="current"
      [totalPages]="totalPages"
      [totalElements]="totalElements"
      (goTo)="onGoTo($event)"
      (next)="onNext($event)"
      (previous)="onPrevious($event)">
    </app-pagination>
  </div>
</section>
